import React, { Fragment, useState, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import {
    IconButton,
    Stack,
    Flex,
    P,
    ButtonStack,
    useToast,
    Badge,
    Auth,
    formatDateTime,
    HeaderSection,
    TabPanel,
    Tab,
    Box
} from 'paid-ui-lib'
import { useRequest } from 'components/use-request'
import SurveyTableSection from 'components/SurveyTableSection'
import SurveyInfoModal from 'components/SurveyInfoModal'

const surveyHeadings = [
    { "header": "Name", "accessor": "name", minWidth: "35%", maxWidth: "35%" },
    { "header": "Assigned To", "accessor": "assignedTo", maxWidth: "10%", "overflow": "visible" },
    { "header": "Status", "accessor": "status", maxWidth: "25%", minWidth: "30%", "overflow": "visible" },
    { "header": "", "accessor": "actions", maxWidth: "30%", minWidth: "30%"}
]

const sections = {
    "awaitingSignOff": { title: "Awaiting Approval", buttonLabel: "Awaiting Approval", value: 4, key: "AwaitingSignOff", hideIfEmpty: true },
    "buyer": { title: `Buyer to complete`, buttonLabel: "Buyer", value: 1, key: "Buyer" },
    "supplier": { title: `Supplier to complete`, buttonLabel: "Supplier", value: 2, key: "Supplier" },
    "thirdParty": { title: "Third Party Vendor to complete", buttonLabel: "Third Party", value: 3, key: "ThirdParty" },
}

const PageSize = 10

const Surveys = ({ organisationId, enterpriseId, onRefresh, ...rest }) => {
    const toast = useToast();
    const { postRequest } = useRequest()
    const [selectedInfoSurvey, setSelectedInfoSurvey] = useState(null)

    const [sectionsData, setSectionsData] = useState({})

    const [dataLoaded, setDataLoaded] = useState(false)
    const [dataError, setDataError] = useState(null)

    const hasSurveys = Object.values(sectionsData).some(v => !!v.items && v.items.length > 0)

    const getInitialQuery = () => {
        const query = {
            categories: []
        }

        for (const surveyTarget in sections) {
            query.categories.push({
                surveyCategory: surveyTarget,
                page: 1,
                PageSize
            })
        }

        return query
    }

    useEffect(() => {
        getSurveyData(getInitialQuery())
    }, [organisationId])

    const getSurveyData = async (query) => {
        postRequest(`organisations/${organisationId}/${enterpriseId}/surveys`, query)
            .then(res => {
                const data = {...sectionsData}

                for (const surveyTarget in res.targets) {
                    data[surveyTarget] = res.targets[surveyTarget]
                }

                setSectionsData(data)
                setDataLoaded(true)
            })
            .catch(() => {
                setDataError(true)
            })
    }

    const surveyTransform = (data) => {
        return {
            id: data.id,
            status: data.responded ? <Badge status="success">Complete</Badge> : <Badge status="warning">Awaiting Completion</Badge>,
            created: data.created,
            name: data.name.length <= 30 ? data.name : (data.name.substring(0, 30) + "…"),
            dateResponded: formatDateTime(data.responded),
            actions: (
                <Flex width="100%">
                    <ButtonStack ml="auto" row spacing={8}>
                        <IconButton isSmall title="View Survey Info" onClick={() => setSelectedInfoSurvey(data)} icon="info"/>
                        {data.surveyLink && <IconButton title="Open Survey" as="a" isSmall target="_blank" href={`${data.surveyLink}`} icon="edit"/>}
                        {data.documentLink && <IconButton title="Open Survey Document" as="a" isSmall target="_blank" href={`${data.documentLink}`} icon="external-link"/>}
                    </ButtonStack>
                </Flex>
            )
        }
    }

    const renderTabContent = (categories) => {
        const getData = (category) => (
            (pageData) => {
                getSurveyData({ categories: [{
                    ...pageData,
                    surveyCategory: category
                }] })
            }
        )

        return (
            <Stack mt={24}>
                {categories
                    .filter(c => categories.length === 1 || (c in sectionsData && sectionsData[c].items.length > 0))
                    .map(c => (
                        <SurveyTableSection 
                            key={`survey-section-${c}`} 
                            headings={surveyHeadings} 
                            title={sections[c].title} 
                            data={sectionsData[c]} 
                            getData={getData(c)} 
                            surveyTransform={surveyTransform} 
                        />
                    ))}
            </Stack>
        )
    }

    const renderTabs = () => {
        const tabs = [
            <Tab key={`survey-section-tab-all`} component={renderTabContent(Object.keys(sections))} activityCount={Object.values(sectionsData).reduce((sum, section) => sum + section.totalResults, 0)}>All</Tab>
        ]

        for (const category of Object.keys(sections)) {
            const section = sections[category]

            if (!!section.hideIfEmpty && (!category in sectionsData || sectionsData[category].totalResults === 0)) {
                continue
            }

            tabs.push(<Tab key={`survey-section-tab-${section.value}`} component={renderTabContent([category])} activityCount={sectionsData[category].totalResults}>{section.buttonLabel}</Tab>)
        }

        return tabs
    }

    return (
        <LoadingScreen
            hasData={dataLoaded}
            hasError={dataError}
            render={() => (
                <HeaderSection
                    title="Surveys"
                    headingMb={hasSurveys ? 1 : null}
                    {...rest}>
                    { !hasSurveys ? 
                        <Box><P>No Surveys Found</P></Box> : 
                        <Stack>
                            <TabPanel tabStyle="button" selectedTab={0}>                                            
                                {renderTabs()}
                            </TabPanel>
                        </Stack>                                    
                            
                    }
                <SurveyInfoModal selectedSurvey={selectedInfoSurvey} enterpriseId={enterpriseId} onClose={() => setSelectedInfoSurvey(null)} />
                </HeaderSection>
            )}/>
    )
}

export default Surveys