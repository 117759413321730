import React, { useEffect, useState } from 'react'
import { useRequest }  from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    P,
    Stack,
    HeaderSection,
    Box,
    UploadedFile
} from 'paid-ui-lib'

const PricingSchedules = ({organisationId, enterpriseId, onRefresh, ...rest}) => {
    const { getRequest } = useRequest()
    const [schedules, setSchedules] = useState([])
    
    useEffect(() => {
        getRequest(`organisations/${organisationId}/${enterpriseId}/pricing-schedules`)
        .then(res => {
            setSchedules(res)
        })
    }, [])

    return (
        <LoadingScreen
            hasData={schedules}
            hasError={false}
            render={() => (
                <HeaderSection
                    title="Pricing Schedule"
                    {...rest}>
                        <Box>
                        {
                            schedules.length === 0
                            ? <P>No Pricing Schedules Found</P>
                            : (
                                <Stack>
                                    {schedules.map(ps => <UploadedFile key={ps.id} {...ps}/>)}
                                </Stack>
                            )
                        }
                    </Box>
                </HeaderSection>
        )} />
    )
}

export default PricingSchedules