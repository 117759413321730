import React, { useState, useEffect, Fragment } from 'react'
import {
    Box,
    Button,
    Stack,
    Flex,
    H3,
    H4,
    PseudoElement,
    Img,
    PageContent,
    ButtonStack,
    FilterButton,
    Badge,
    Link,
    Label,
    Icon,
    Avatar 
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import { convertStatusState } from 'helpers/status-helper'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'
import { formatDateTime } from 'helpers/date-helper'

const WorkTab = ({ organisation, location }) => {
    const [selectedEnterprise, setSelectedEnterprise] = useState(null)
    const [organisations, setOrganisations] = useState([])
    
    const { getRequest, postRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [pageData, setPageData] = useState()
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState(false)
    const [activeFilters, setActiveFilters] = useState()
    const [workDownload, setWorkDownload] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [tableLoaded, setTableLoaded] = useState(false)
    
    const transformFilters = (filters) => {
        return filters
    }

    const transformQuickFilters = (quickFilters) => {
        return quickFilters
    }

    useEffect(() => {
        if (!!selectedEnterprise) {
            initFilteredDataTable({
                filterPath: 'work/filters',
                dataPath: `work/search`,
                location: location,
                setFilterData: setFilterData,
                setDataError: setDataError,
                queryParams: [
                    {"enterpriseId": selectedEnterprise.id}
                ]
            }).then(showFilter => {
                setShowFilter(showFilter)
                setTableLoaded(true)
            })
        }
    }, [selectedEnterprise])

    const columns = [
        { "header": "Reference", "accessor": "reference", "sortId": "1" },
        { "header": "Supplier", "accessor": "supplier", "sortId": "4" },
        { "header": "Title", "accessor": "title", "sortId": "3", maxWidth: "20rem" },
        { "header": "Owner", "accessor": "owner", "sortId": "5" },
        { "header": "Amount", "accessor": "amount", "sortId": "9" },
        { "header": "Updated", "accessor": "updatedOn", "sortId": "6" },
        { "header": "Status", "accessor": "status", "sortId": "7" },
    ]

    const transform = (data) => {
        return {
            id: data.id,
            reference: <Link href={`work/${data.enterprise.id}/${data.id}`} openInNewTab={true}>{data.reference}</Link>,
            title: data.title,
            supplier: <Link action={`organisations/${data.enterprise.id}/${data.supplier.id}`} openInNewTab={true}>{data.supplier.name}</Link>,
            owner: <Avatar name={data.procurementOwner.name} size={28} />,
            updatedOn: formatDateTime(data.updatedOn),
            status: <Badge status={convertStatusState(data.status.state)}>{data.status.message}</Badge>,
            amount: `${data.currencySymbol || "£"}${data.amount.toFixed(2)}`,
        }
    }

    const getFilterParams = () => {
        return {
            filters: activeFilters.map(af => af.filter),
            page: pageData.page,
            pageSize: pageData.pageSize
        }
    }

    const downloadFile = (path, fileName) => {
        const filters = getFilterParams()
        return postRequest(`${path}`, filters)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a) 
                a.click()
                a.remove()       
            })
    }

    const downloadWorkCsv= () => {
        setWorkDownload(true)
        downloadFile(`work/export/${selectedEnterprise.id}`, `${selectedEnterprise.name} - Work-${new Date()}.csv`)
            .finally(() => setWorkDownload(false))
    }

    const groupBy = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n) 
            result.push(data.slice(i, i + n))
        
        result[result.length-1].length = 4
        result[result.length-1] = Array.from(result[result.length-1], (v) => v == undefined ? "padding" : v) 

        return result
    };

    const backPressed = () => {
        setSelectedEnterprise(null)
        setShowFilter(false)
        location.state.selectedOrg = null
    }

    useEffect(() => {
        if (!!organisations)
            getRequest("enterprise", {active: true})
                .then((res) => {
                    let test = organisation.clients.map(c => c.enterpriseId)
                    setOrganisations(res.filter(c => test.includes(c.id)))
                    setDataError(false)
                })
    }, [])

    return (
        <LoadingScreen
            hasData={!!selectedEnterprise ? tableLoaded : organisations.length > 0}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageContent slim>
                        <Stack>
                            {
                                !!selectedEnterprise && 
                                    <Stack spacing={8} row alignItems="center">
                                        <Label mt="auto" mb="auto" color="blue.600" onClick={() => backPressed()}><Icon name="arrow-left"></Icon> All clients /</Label>
                                        <H3 mt="auto" mb="auto" mr="auto">{selectedEnterprise.name}</H3>
                                        <ButtonStack row>
                                            <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                                            <Button onClick={() => downloadWorkCsv()} variant="secondary" aria-label="Download" isLoading={workDownload} showChevron>{workDownload ? "Downloading..." : "Download"}</Button>
                                        </ButtonStack>
                                        <Box mt="auto" mb="auto" padding={8} >
                                            <Img src={selectedEnterprise.logo} alt="Logo" height="2rem" />
                                        </Box>
                                    </Stack>
                            }
                            {
                                !!selectedEnterprise ? 
                                        <Stack>
                                            <PageContent slim>
                                                <FilteredDataTable
                                                    initData={filterData}
                                                    showFilter={showFilter}
                                                    setShowFilter={setShowFilter}
                                                    setDataError={setDataError}
                                                    transform={transform}
                                                    columns={columns}
                                                    transformFilters={transformFilters}
                                                    transformQuickFilters={transformQuickFilters}
                                                    setParentActiveFilters={setActiveFilters}
                                                    setParentPageData={setPageData}
                                                    sortable={true}
                                                    refresh={refresh}
                                                    setRefresh={setRefresh}
                                                    queryParams={[
                                                        {"enterpriseId": selectedEnterprise.id},
                                                        {"organisationId": organisation.id}
                                                    ]}
                                                />
                                            </PageContent>
                                        </Stack> 
                                :
                                <Stack>
                                {
                                    groupBy(4, organisations).map((row, i) => 
                                        <Stack key={`row-${i}`} row flex="4">
                                            {
                                                row.length > 0 && row.map((org, i) => (
                                                    org == "padding" ? <PseudoElement flex="1"/> :
                                                    <Stack flex="1" key={`item-${i}`} mt={32} spacing={0} responsive>
                                                        <Box>
                                                            <Flex>
                                                                <Flex flexDirection="column">
                                                                    <Flex alignItems="center">
                                                                        <Flex mb={4} alignItems="center" flexDirection="row" flexWrap="wrap">
                                                                            <H4 accessibilityHeading="h3" flexShrink="0" mr={8}>{org.name}</H4>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                                <PseudoElement ml="auto">
                                                                    <Box padding={8}>
                                                                        <Img src={org.logo} alt="Logo" height="2rem" />
                                                                    </Box>
                                                                </PseudoElement>
                                                            </Flex>
                                                            <Button mt={12} variant="secondary" onClick={() => setSelectedEnterprise(org)}>View Work</Button>
                                                        </Box>
                                                    </Stack>
                                                )
                                            )}
                                        </Stack>
                                    )
                                }                            
                            </Stack>
                            }
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default WorkTab