import React, { Fragment, useState, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import NoDataScreen from 'components/NoDataScreen'
import { useRequest } from 'components/use-request'
import {
    PageTitle,
    formatDateTime,
    PageContent,
    Table,
    Link
} from 'paid-ui-lib'

const ListEnterpriseView = () => {
    const [enterprises, setBuyers] = useState([])
    const [dataError, setDataError] = useState(false)
    const { getRequest } = useRequest()

    useEffect(() => {
        getRequest('enterprise?active=true')
            .then(setBuyers)
            .catch(() => setDataError(true))
    }, [])

    const enterpriseHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Supplier Count", "accessor": "supplierCount" },
        { "header": "Work Count", "accessor": "workCount" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Updated On", "accessor": "updatedOn" }
    ]

    const enterpriseTransform = (data) => {
        return {
            name: <Link action={`/enterprise/${data.id}`}>{data.name}</Link>,
            supplierCount: data.supplierCount,
            workCount: data.projectCount,
            createdOn: formatDateTime(data.createdOn),
            updatedOn: !!data.updatedOn ? formatDateTime(data.updatedOn) : "N/A",
        }
    }

    return (
        <LoadingScreen
            hasData={enterprises.length > 0}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Enterprise" description="The enterprise base" slim />
                    <PageContent slim>
                        {
                            <Table columns={enterpriseHeadings} data={enterprises} transform={enterpriseTransform} noData={<NoDataScreen />} />
                        }
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ListEnterpriseView