import React, { useState, useEffect } from 'react'
import {
    Stack,
    HeaderSection,
    Box,
    Field,
    FooterMonthBarChart
}
from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import cronstrue from 'cronstrue'

const BuyerDetailsTab = ({buyer}) => {
    const { getRequest } = useRequest()
    const [express, setExpress] = useState(null)
    const [standard, setStandard] = useState(null)
    const [dataError, setDataError] = useState(false)
    
    useEffect(() => {
        getRequest(`payments/${buyer.id}/average?express=true`)
        .then(res => {
            let values = []
            res.forEach(element => {
                values.push({
                    "index": element.key,
                    "value": element.value
                })
            });

            setExpress(values.reverse())
        })
        .catch(() => {
            setDataError(true)
        })

        getRequest(`payments/${buyer.id}/average?express=false`)
        .then(res => {
            let values = []
            res.forEach(element => {
                values.push({
                    "index": element.key,
                    "value": element.value
                })
            });

            setStandard(values.reverse())
        })
        .catch(() => {
            setDataError(true)
        })
    }, [])

    return (
        <LoadingScreen
            hasData={!!express}
            hasError={dataError}
            render={() => (
                <Stack>
                    <Stack row responsive flex="2">
                        <HeaderSection title="Details"flex={"1"}>                   
                            <Box>
                                <Stack row responsive>
                                    <Stack flex="1">
                                        <Field label="Id" value={buyer.id} />
                                        <Field label="Title" value={buyer.name} />
                                        <Field label="Credit Limit" value={buyer.creditLimit} />
                                    </Stack>
                                    <Stack flex="1">
                                        <Field label="App Url" value={buyer.appUrl} />
                                        <Field label="Api Url" value={buyer.apiUrl} />
                                        <Field label="Api Key" value={buyer.apiKey} flex="1" />
                                    </Stack>
                                </Stack>    
                            </Box>
                        </HeaderSection>                
                    </Stack>
                    <Stack row responsive flex="2">
                        <HeaderSection title="Billing Terms" flex="1">
                            <Box>
                                <Stack >
                                    <Field label="Invoice Schedule" value={cronstrue.toString(buyer.billingTerms.invoiceSchedule, { use24HourTimeFormat: true })} />
                                    <Field label="Payment Term Days" value={buyer.billingTerms.paymentTermDays} />
                                    <Field label="Payment Rate" value={buyer.billingTerms.paymentRate} />
                                </Stack>
                            </Box>
                        </HeaderSection>
                        <HeaderSection title="Instant Billing Terms" flex="1">
                            <Box>
                                <Stack >
                                    <Field label="Invoice Schedule" value={cronstrue.toString(buyer.instantBillingTerms.invoiceSchedule, { use24HourTimeFormat: true })} />
                                    <Field label="Payment Term Days" value={buyer.instantBillingTerms.paymentTermDays} />
                                    <Field label="Payment Rate" value={buyer.instantBillingTerms.paymentRate} />
                                </Stack>
                            </Box>
                        </HeaderSection>
                    </Stack>
                    <Stack row responsive flex="2">
                        <HeaderSection title="Average Payment Statistics" flex="1">
                            <Box>
                                <Stack >
                                    <Field label="Average Express Payment" value={<FooterMonthBarChart maxHeight={60} values={express} annotations={["hour", "hours"]} />} />
                                    <Field label="Average Standard Payment" value={<FooterMonthBarChart maxHeight={60} values={standard} annotations={["hour", "hours"]} />} />
                                </Stack>
                            </Box>
                        </HeaderSection>
                    </Stack>
                </Stack>
            )}
        />
    )    
}

export default BuyerDetailsTab