import React from 'react'
import {
    Stack,
    Box,
    Icon,
    Label,
    Img,
    H2,
    HR,
} from 'paid-ui-lib'
import Surveys from './components/Surveys'
import Agreements from './components/Agreements'
import PricingSchedules from './components/PricingSchedules'
import Attachments from './components/Attachments'

const DocumentsTab = ({organisationId, enterpriseId, onRefresh, returnClick, organisation, ...rest}) => {
    return (
            <Stack {...rest}>
                {!!organisation && 
                    <Stack mt={20} spacing={8} row alignItems="center">
                        <Label mt="auto" mb="auto" color="blue.600" onClick={returnClick}><Icon name="arrow-left"></Icon> All clients /</Label>
                        <H2 mt="auto" mb="auto" mr="auto">{organisation.name}</H2>
                        <Box mt="auto" mb="auto" padding={8} >
                            <Img src={organisation.logo} alt="Logo" height="2rem" />
                        </Box>
                    </Stack>
                }
                <Stack>
                    <Agreements mb={8}
                        organisationId={organisationId}
                        enterpriseId={enterpriseId}
                        onRefresh={onRefresh} />
                    <HR />
                    <Surveys
                        organisationId={organisationId}
                        enterpriseId={enterpriseId}
                        onRefresh={onRefresh} />
                    <HR />
                    <PricingSchedules
                        organisationId={organisationId}
                        enterpriseId={enterpriseId}
                        onRefresh={onRefresh} />
                    <HR />
                    <Attachments
                        organisationId={organisationId}
                        enterpriseId={enterpriseId}
                        onRefresh={onRefresh} />
                </Stack>
            </Stack>
    )
}

export default DocumentsTab