import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    Badge,
    Box,
    Button,
    ButtonStack,
    Flex,
    Field,
    formatDateTime,
    TabPanel,
    H2,
    HR,
    Link,
    PageContent,
    PageTitle,
    Stack,
    Span,
    Switch,
    Tab,
} from 'paid-ui-lib'
import NoteViewerDraw from 'components/NoteViewerDraw'
import DocumentsTab from './components/DocumentsTab'
import LoadingScreen from 'components/LoadingScreen'
import DetailsTab from './components/DetailsTab'
import { useLocation } from 'react-router-dom'
import UsersTab from './components/UsersTab'
import WorkTab from './components/WorkTab'
import OnboardingTab from './components/OnboardingTab'
import FinanceTab from './components/FinanceTab'
import ConfirmModal from 'components/ConfirmModal'

const ViewSupplierView = (props) => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0)
    const [financeOptions, setFinanceOptions] = useState(null)
    const [showSuspend, setShowSuspend] = useState(false)
    const [showKycRequirement, setShowKycRequirement] = useState(false)
    const [showFinanceOptions, setShowFinanceOptions] = useState(false)
    const [showPaymentsSupport, setShowPaymentsSupport] = useState(false)
    const [showActionVirtualCards, setShowActionVirtualCards] = useState(false)
    const [showNotes, setShowNotes] = useState(false)
    const { getRequest, putRequest, postRequest } = useRequest()
    const [organisation, setOrganisation] = useState(undefined)
    const [dataError, setDataError] = useState(false)
    const [turnOnVirtualCards, setTurnOnVirtualCards] = useState(false)

    const getOrganisationFinanceOptions = (organisationId) => {
        getRequest(`finance/organisations/${organisationId}/finance-options`)
            .then(res => setFinanceOptions(res))
            .catch(() => setDataError(true))
    }

    const getOrganisation = (organisationId) => {
        getRequest(`organisations/${organisationId}`)
            .then(res => setOrganisation({ ...res, address: res.address || {} }))
            .catch(() => setDataError(true))
    }

    const updatePaymentSupport = (organisationId, paymentsEnabled) => {
        return putRequest(`organisations/${organisationId}/requirements`, { paymentsEnabled: paymentsEnabled })
            .then(() => setOrganisation(null))
            .catch(() => setDataError(true))
            .finally(() => { setShowPaymentsSupport(false); refresh() });
    }

    const updateVirtualCardSupport = (organisationId, virtualCardsEnabled) => {
        return putRequest(`organisations/${organisationId}/requirements`, { virtualCardsEnabled: virtualCardsEnabled, turnOnVirtualCards: turnOnVirtualCards })
            .then(() => setOrganisation(null))
            .catch(() => setDataError(true))
            .finally(() => { setShowActionVirtualCards(false); refresh() });
    }

    const updateFinanceOptions = (organisationId, allowFinance) => {
        return postRequest(`finance/organisations/${organisationId}/finance-options/${organisationId}`, { enabled: allowFinance })
            .then(() => setOrganisation(null))
            .catch(() => setDataError(true))
            .finally(() => { setShowFinanceOptions(false); refresh() });
    }

    const updateKycRequirements = (organisationId, kycEnabled) => {
        return putRequest(`organisations/${organisationId}/requirements`, { kycEnabled: kycEnabled })
            .then(() => setOrganisation(null))
            .catch(() => setDataError(true))
            .finally(() => { setShowKycRequirement(false); refresh() });
    }

    
    const updateSuspended = (organisationId, suspended) => {
        return putRequest(`organisations/${organisationId}/state`, { suspended: suspended })
            .then(() => setOrganisation(null))
            .catch(() => setDataError(true))
            .finally(() => { setShowSuspend(false); refresh() });
    }

    const refresh = () => {
        getOrganisation(props.match.params.organisationId);
        getOrganisationFinanceOptions(props.match.params.organisationId);
    }

    useEffect(() => {
        refresh();
    }, [props.match.params.organisationId])

    return (
        <LoadingScreen
            hasData={!!organisation}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <ConfirmModal
                        isOpen={showSuspend}
                        buttonText={!!organisation && !!organisation.suspended ? "Unsuspend" : "Suspend"}
                        buttonVariant={!!organisation && !!organisation.suspended ? "primary" : "destructive"}
                        message={`Are you sure you would like to ${!!organisation && !!organisation.suspended ? "unsuspend" : "suspend"} this supplier?`}
                        onConfirm={() => updateSuspended(organisation.id, !!organisation.suspended ? false : true)}
                        onClose={() => setShowSuspend(false)} />
                    <ConfirmModal
                        isOpen={showKycRequirement}
                        buttonText={!!organisation && !!organisation.kycEnabled ? "Disable" : "Enable"}
                        buttonVariant={!!organisation && !!organisation.kycEnabled ? "destructive" : "primary"}
                        message={`Are you sure you would like to ${!!organisation && !!organisation.kycEnabled ? "disable" : "enable"} all director kyc checks for this supplier?`}
                        onConfirm={() => updateKycRequirements(organisation.id, !!organisation.kycEnabled ? false : true)}
                        onClose={() => setShowKycRequirement(false)} />
                    <ConfirmModal
                        isOpen={showPaymentsSupport}
                        buttonText={!!organisation && !!organisation.paymentsEnabled ? "Disable" : "Enable"}
                        buttonVariant={!!organisation && !!organisation.paymentsEnabled ? "destructive" : "primary"}
                        message={`Are you sure you would like to ${!!organisation && !!organisation.paymentsEnabled ? "disable" : "enable"} payments for this supplier?`}
                        onConfirm={() => updatePaymentSupport(organisation.id, !!organisation.paymentsEnabled ? false : true)}
                        onClose={() => setShowPaymentsSupport(false)} />
                    <ConfirmModal
                        isOpen={showFinanceOptions}
                        buttonText={!!financeOptions && !!financeOptions.financeDisabled ? "Enable" : "Disable"}
                        buttonVariant={!!financeOptions && !!financeOptions.financeDisabled ? "primary" : "destructive"}
                        message={`Are you sure you would like to ${!!financeOptions && !!financeOptions.financeDisabled ? "enable" : "disable"} express payment options for this supplier?`}
                        onConfirm={() => updateFinanceOptions(organisation.id, !!financeOptions.financeDisabled ? true : false)}
                        onClose={() => setShowFinanceOptions(false)} />
                    <ConfirmModal
                        isOpen={showActionVirtualCards}
                        buttonText={!!organisation.allowVirtualCards ? "Disable" : "Enable"}
                        buttonVariant={!!organisation.allowVirtualCards ? "destructive" : "primary"}
                        message={`Are you sure you would like to ${organisation.allowVirtualCards ? "disable" : "enable"} virtual card payments?`}
                        onConfirm={() => updateVirtualCardSupport(organisation.id, !!organisation.allowVirtualCards ? false : true)}
                        onClose={() => setShowActionVirtualCards(false)}>
                        {
                            !organisation.allowVirtualCards &&
                            <Stack py={16} flex="1" spacing={12} alignItems="center" row>
                                <Switch checked={turnOnVirtualCards} onClick={() => setTurnOnVirtualCards(t => !t)} />
                                <Span>Turn on virtual cards?</Span>
                            </Stack>
                        }
                    </ConfirmModal>
                    <PageTitle title={organisation.name} description="View the organisation's details" status={organisation.status} slim>
                        <ButtonStack row>
                            <Button variant="secondary" onClick={() => setShowNotes(true)}>View Notes</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <Stack row responsive responsiveReverse>
                            <TabPanel selectedTab={selectedTab} flex="1">
                                <Tab icon="file" component={<DetailsTab organisation={organisation} />}>Details</Tab>
                                <Tab icon="users" component={<UsersTab organisation={organisation} onRefresh={() => getOrganisation(organisation.id)} location={location} />}>Users</Tab>
                                <Tab icon="folder" component={<WorkTab organisation={organisation} location={location} />}>Work</Tab>
                                <Tab icon="file" component={<DocumentsTab organisation={organisation} onRefresh={() => getOrganisation(organisation.id)} />}>Documents</Tab>
                                <Tab icon="file" component={<OnboardingTab organisation={organisation} />}>Onboarding</Tab>
                                <Tab icon="file" component={<FinanceTab organisation={organisation} />}>Finance</Tab>
                            </TabPanel>
                            <Stack
                                width={["100%", "300px", "300px"]}
                                mt={[0, 32, 32]}
                                mb={[20, 0, 0]}>
                                <ButtonStack>
                                    <Button variant={!!organisation.suspended ? "primary" : "destructive"} onClick={() => setShowSuspend(true)}>{`${!!organisation.suspended ? "Unsupend" : "Suspend"}`}</Button>
                                    <Button variant={!!organisation.kycEnabled ? "destructive" : "primary"} onClick={() => setShowKycRequirement(true)}>{`${!!organisation.kycEnabled ? "Disable" : "Enable"} AML/KYC Checks`}</Button>
                                    <Button variant={!!organisation.paymentsEnabled ? "destructive" : "primary"} onClick={() => setShowPaymentsSupport(true)}>{`${!!organisation.paymentsEnabled ? "Disable" : "Enable"} Payments`}</Button>
                                    {!!organisation.paymentsEnabled && <Button variant={!!organisation.allowVirtualCards ? "destructive" : "primary"} onClick={() => setShowActionVirtualCards(true)}>{`${!!organisation.allowVirtualCards ? "Disable" : "Enable"} Virtual Card Payments`}</Button>}
                                    {!!organisation.paymentsEnabled && !!financeOptions && <Button variant={financeOptions.financeDisabled ? "primary" : "destructive"} onClick={() => setShowFinanceOptions(true)}>{`${!!financeOptions && !!financeOptions.financeDisabled ? "Enable" : "Disable"} Novuna Express Payments`}</Button>}
                                </ButtonStack>
                                <Box flex="0 1 auto" accent="blue">
                                    <Stack>
                                        <Stack>
                                            <H2>Organisation Details</H2>
                                            <Field label="Created On" value={formatDateTime(organisation.createdOn)} />
                                            {
                                                (organisation.createdBy) &&
                                                <Field label="Created By">
                                                    <Link action={`/users/${organisation.createdBy.id}`}>{organisation.createdBy.name}</Link>
                                                </Field>
                                            }
                                            <Field label="User Count" value={!!organisation.users && organisation.users.length} />
                                            <Field label="Work Count" value={organisation.workCount} />
                                            {
                                                (organisation.supplierSignOffEnabled) &&
                                                <Field label="Signed Off" value={formatDateTime(organisation.signedOffOn)} />
                                            }
                                            <Field label="Last Login" value={formatDateTime(organisation.lastLogin)} />
                                            {!!organisation && <Field label="AML / KYC" value={!!organisation.kycEnabled ? <Badge state="error">Required</Badge> : <Badge state="info">Optional</Badge>} />}
                                            {!!organisation &&
                                                <Field label="Virtual Cards">
                                                    <Stack row>
                                                        <Flex>{!!organisation.allowVirtualCards ? <Badge state="success">Available</Badge> : <Badge state="error">Unavailable</Badge>}</Flex>
                                                        <Flex>{!!organisation.virtualCardsEnabled ? <Badge state="success">Turned On</Badge> : <Badge state="error">Turned Off</Badge>}</Flex>
                                                    </Stack>
                                                </Field>}
                                            {!!financeOptions && <Field label="Novuna Express Payments" value={(!organisation.paymentsEnabled || !!financeOptions.financeDisabled) ? <Badge state="error">Unavailable</Badge> : <Badge state="success">Available</Badge>} />}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </PageContent>
                    <NoteViewerDraw notes={organisation.notes} onClose={() => setShowNotes(false)} isOpen={showNotes} />
                </Fragment>
            )} />
    )
}

export default ViewSupplierView