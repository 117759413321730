import React, {useEffect} from 'react'
import {
    Stack,
    HeaderSection,
    Box,
    Field,
    PseudoElement,
    Flex,
    Badge,
    Tags
}
from 'paid-ui-lib'
import FormatList from 'components/FormatList'

const DetailsTab = ({organisation, ...rest}) => {
    useEffect(() => {
    }, [])

    const getPaymentTerms = () => {
        let response = []
        for (var key in organisation.paymentTerms){
            response.push(`${key}: ${organisation.paymentTerms[key]} days`)
          }

        return response;
    }

    return (       
        <Stack>
            <HeaderSection
                title="Overview"
                {...rest}>
                <Box>
                    <Stack>
                        <Stack row responsive>
                            <Stack flex="1">
                                <Field label="Name:" value={organisation.name} />
                                <Field label="Telephone:" value={organisation.telephone} />
                                <Field label="Additional Telephone:" value={organisation.mobile} />
                                <Field label="Email:" value={organisation.email} />
                                <Field label="Payment Terms:"><FormatList spacing={1} items={getPaymentTerms()}></FormatList></Field>
                                <Field label="Procurement Department:" value={(organisation.invitedProcurementDepartment || {}).name} />
                            </Stack>
                            <Stack flex="1">
                                <Field label="Company Number:" value={organisation.companyNumber} />
                                <Field label="VAT Number:" value={organisation.vatNumber} />
                                {
                                    !!organisation.vatNumber &&
                                    <Field label="VAT Rate:" value={!!organisation.vatRate ? organisation.vatRate + "%" : "" } />
                                }
                                <Field
                                    label="Address:">
                                    {
                                        organisation.isVerifiedAddress
                                            ? (
                                                <FormatList spacing={1} items={[
                                                    organisation.address.buildingNumber,
                                                    organisation.address.addressLine1,
                                                    organisation.address.addressLine2,
                                                    organisation.address.city,
                                                    organisation.address.county,
                                                    organisation.address.postCode,
                                                    organisation.address.country,
                                                ]} />
                                            )
                                            : (
                                                <PseudoElement
                                                    border="1"
                                                    borderColor="orange.300"
                                                    backgroundColor="orange.100"
                                                    borderRadius="default"
                                                    p={4}
                                                >
                                                    <Flex alignItems="flex-top">
                                                        <FormatList spacing={1} items={[
                                                            organisation.address.buildingNumber,
                                                            organisation.address.addressLine1,
                                                            organisation.address.addressLine2,
                                                            organisation.address.city,
                                                            organisation.address.county,
                                                            organisation.address.postCode,
                                                            organisation.address.country,
                                                        ]} />
                                                        <PseudoElement ml={[0, "auto", "auto"]}>
                                                            <Badge status="warning">Unverified</Badge>
                                                        </PseudoElement>
                                                    </Flex>
                                                </PseudoElement>
                                            )
                                    }
                                </Field>
                                <Field label="Procurement Types:" flex="1">
                                    {organisation.procurementTypes.length && <Tags value={organisation.procurementTypes} />}
                                </Field>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </HeaderSection>
        </Stack>
    )
    
}

export default DetailsTab