import React, { useState, useEffect, Fragment } from 'react'
import {
    Box,
    Button,
    Stack,
    Flex,
    H3,
    H4,
    PseudoElement,
    Img,
    PageTitle,
    PageContent,
    IconSquare,
    ButtonStack,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    IconButton,
    formatDate,
    getMonth,
    FilterButton,
    Badge,
    Link,
    Label,
    Icon,
    DatePicker,
    formatDateString
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import { convertStatusState } from 'helpers/status-helper'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'

const TransactionalInvoicePeriods = (props) => {
    const { getRequest, postRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [organisations, setOrganisations] = useState(undefined)

    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [activeFilters, setActiveFilters] = useState()
    const [pageData, setPageData] = useState()
    const [invoiceDownload, setInvoiceDownload] = useState(false)
    const [invoiceExport, setInvoiceExport] = useState(false)
    const [ refresh, setRefresh ] = useState(false)

    const [period, setPeriod] = useState(formatDateString(new Date(), "yyyy-MM"))

    const transformFilters = (filters) => { return filters }
    const transformQuickFilters = (quickFilters) => { return quickFilters }

    const columns = [
        { "header": "Reference", "accessor": "workReference", "sortId": "2" },
        { "header": "Supplier", "accessor": "supplier", "sortId": "3" },
        { "header": "Value", "accessor": "amount", "sortId": "4" },
        { "header": "Terms", "accessor": "paymentTerms", "sortId": "5" },
        { "header": "Due", "accessor": "dueOn", "sortId": "6" },
        { "header": "Created On", "accessor": "createdOn", "sortId": "7" },
        { "header": "Period", "accessor": "period", "sortId": "8" },
        { "header": "Status", "accessor": "status", "sortId": "9" },
        { "header": "", "accessor": "pdfLink" },
    ]

    const transform = (data) => {
        console.log(data)
        return {
            id: data.id,
            buyer: data.buyerName,
            workReference: <Link action={`/work/${data.buyerId}/${data.work.id}`}>{data.work.name}</Link>,
            supplier: <Link action={`/organisations/${data.buyerId}/${data.supplier.id}`}>{data.supplier.name}</Link>,
            period: getMonth(data.period),
            amount: `${(!!data.currencySymbol ? data.currencySymbol : "")}${data.amount.toFixed(2)} ${!!data.vatRegistered ? "Ex. Vat" : ""}`,
            dueOn: formatDate(data.dueOn),
            createdOn : formatDate(data.createdOn),
            paymentTerms: <PseudoElement><IconSquare size={32} title={!!data.expressPayment ? "Express Payment" : "Standard Terms"} name={!!data.expressPayment ? "express-payment" : "credit-card"} /></PseudoElement>,
            status: <Badge status={convertStatusState(data.status.state)}>{data.status.message}</Badge>,
            pdfLink:
                (!!data.pdfLink) && (
                    <Flex width="100%">
                        <ButtonStack ml="auto" compact row>
                            <IconButton as="a" isSmall variant="secondary" target="_blank" href={`${data.pdfLink}&open=true`} icon="external-link" />
                            <IconButton as="a" isSmall variant="secondary" target="_blank" href={data.pdfLink} icon="download" />
                        </ButtonStack>
                    </Flex>)
        }
    }

    useEffect(() => {
        if (!!selectedOrg) {
            initFilteredDataTable({
                filterPath: "invoices/period/search/filters",
                dataPath: `invoices/period/search?enterpriseId=${selectedOrg.id}&period=${period}&transactional=true`,
                location: props.location,
                setFilterData,
                setDataError
            }).then(showFilter => setShowFilter(showFilter))
        }
    }, [selectedOrg, period])

    const getFilterParams = () => {
        return {
            filters: activeFilters.map(af => af.filter),
            page: pageData.page,
            pageSize: pageData.pageSize
        }
    }

    const downloadFile = (path, fileName) => {
        const filters = getFilterParams()
        return postRequest(path, filters)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                a.remove()       
            })
    }

    const downloadInvoices = () => {
        setInvoiceDownload(true)
        downloadFile(`invoices/period/download?enterpriseId=${selectedOrg.id}&period=${period}&transactional=true`, "invoices.zip")
            .finally(() => setInvoiceDownload(false))
    }

    const exportInvoices = () => {
        setInvoiceExport(true)
        downloadFile(`invoices/period/export?enterpriseId=${selectedOrg.id}&period=${period}&transactional=true`, "invoices.csv")
            .finally(() => setInvoiceExport(false))
    }

    const groupBy = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n) 
            result.push(data.slice(i, i + n))
        
        result[result.length-1].length = 4
        result[result.length-1] = Array.from(result[result.length-1], (v) => v == undefined ? "padding" : v) 

        return result
    };

    useEffect(() => {
        getRequest("enterprise", {active: true})
        .then((res) => {
            setOrganisations(res)
            setDataError(false)
        })
    }, [])

    return (
        <LoadingScreen
            hasData={!!selectedOrg ? !!filterData.data : !!organisations}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Transactional Invoices" description={!!selectedOrg ? `Transactional Invoices for ${selectedOrg.name}` : "Please select an enterprise to view Invoices"} slim>
                        {
                            !!selectedOrg && 
                            <ButtonStack row>
                                <Menu>
                                    <MenuButton variant="secondary" aria-label="Download" isLoading={invoiceDownload || invoiceExport} showChevron>{(invoiceDownload || invoiceExport) ? "Downloading..." : "Download"}</MenuButton>
                                    <MenuList width={["100%","260px","260px"]}>
                                        <MenuItem alignItems="center" onClick={downloadInvoices}>
                                            Download PDF records (ZIP)
                                        </MenuItem>
                                        <MenuItem alignItems="center" onClick={exportInvoices}>
                                            Download invoice data (CSV) 
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                            </ButtonStack>
                        }
                    </PageTitle>
                    <PageContent slim>
                        <Stack>
                            {
                                !!selectedOrg && 
                                <Stack spacing={8} row alignItems="center">
                                    <Label mt="auto" mb="auto" color="blue.600" onClick={() => setSelectedOrg(undefined)}><Icon name="arrow-left"></Icon> All clients /</Label>
                                    <H3 mt="auto" mb="auto" mr="auto">{selectedOrg.name}</H3>
                                    <Box mt="auto" mb="auto" padding={8} >
                                        <Img src={selectedOrg.logo} alt="Logo" height="2rem" />
                                    </Box>
                                </Stack>
                            }
                            {
                                !!selectedOrg ? 
                                <Stack>
                                    <PseudoElement width="250px" height="50px">
                                        <DatePicker placeholderText="Select Period" maxDate={new Date()} dateFormat="MM yyyy" editable={false} startDateIn={period} onChange={(e) => {setPeriod(formatDateString(e.date, "yyyy-MM")); setRefresh(true)}} />
                                    </PseudoElement>
                                    <FilteredDataTable
                                        initData={filterData}
                                        showFilter={showFilter}
                                        setDataError={setDataError}
                                        transform={transform}
                                        columns={columns}
                                        transformFilters={transformFilters}
                                        transformQuickFilters={transformQuickFilters}
                                        setParentActiveFilters={setActiveFilters}
                                        setParentPageData={setPageData}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        sortable={true}
                                        extraQueryParams={`&enterpriseId=${selectedOrg.id}&period=${period}&transactional=true`} />
                                </Stack> :
                                <Stack>
                                {
                                    groupBy(4, organisations).map((row, i) => 
                                        <Stack key={`row-${i}`} row flex="4">
                                            {
                                                row.length > 0 && row.map((org, i) => (
                                                    org == "padding" ? <PseudoElement flex="1"/> :
                                                    <Stack flex="1" key={`item-${i}`} mt={32} spacing={0} responsive>
                                                        <Box>
                                                            <Flex>
                                                                <Flex flexDirection="column">
                                                                    <Flex alignItems="center">
                                                                        <Flex mb={4} alignItems="center" flexDirection="row" flexWrap="wrap">
                                                                            <H4 accessibilityHeading="h3" flexShrink="0" mr={8}>{org.name}</H4>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                                <PseudoElement ml="auto">
                                                                    <Box padding={8}>
                                                                        <Img src={org.logo} alt="Logo" height="2rem" />
                                                                    </Box>
                                                                </PseudoElement>
                                                            </Flex>
                                                            <Button mt={12} variant="secondary" onClick={() => setSelectedOrg(org)}>View Invoices</Button>
                                                        </Box>
                                                    </Stack>
                                                )
                                            )}
                                        </Stack>
                                    )
                                }                            
                            </Stack>
                            }
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default TransactionalInvoicePeriods