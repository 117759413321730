import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRequest } from 'components/use-request'
import {
    Button,
    ButtonStack,
    Box,
    PageContent,
    PageTitle,
    Field,
    H2,
    Stack,
    Tab,
    TabPanel,
    useToast,
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'
import BuyerDetailsTab from './components/BuyerDetailsTab'
import ConfirmModal from 'components/ConfirmModal'

const ViewBuyerView = (props) => {
    const history = useHistory();
    const toast = useToast();
    const { getRequest, postRequest } = useRequest()
    const [financeOptions, setFinanceOptions] = useState(null)
    const [actionFinanceOption, setActionFinanceOption] = useState(null)
    const [enterprise, setEnterprise] = useState(null)
    const [dataError, setDataError] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const enterpriseId = props.match.params.enterpriseId;

    const getEnterprise = () => {
        getRequest(`enterprise/${enterpriseId}`)
            .then(setEnterprise)
            .catch(() => setDataError(true))
    }

    const getFinanceOptions = () => {
        getRequest(`finance/buyers/${enterpriseId}/finance-options`)
            .then(res => setFinanceOptions(res))
            .catch(() => setDataError(true))
    }

    const updateFinanceOptions = () => {
        if (!actionFinanceOption) return;
        const enabled = !actionFinanceOption.enabled;
        return postRequest(`finance/organisations/${enterpriseId}/finance-options/${actionFinanceOption.id}`, { enabled: enabled })
            .then(r => {
                getEnterprise(r);
                toast({ slim: true, position: "top-right", title: `Finance ${enabled ? 'Enabled' : 'Disabled'}`, description: `Finance provider successfully updated`, status: "success", isClosable: true })
            })
            .catch(() => {
                setDataError(true);
                toast({ slim: true, position: "top-right", title: 'Failed', description: `Failed to update finance provider. Please contact support.`, status: "error", isClosable: true })
            })
            .finally(() => setActionFinanceOption(null));
    }

    const workTabClick = () => {
        history.push("/work")
    }

    const suppliersTabClick = () => {
        history.push({
            pathname: "/organisations",
            state: {
                filters: [
                    {
                        "id": "cb451f79-0f99-4446-a783-e433f700840e",
                        "filter": {
                            "id": "6",
                            "values": {
                                "0": "1",
                                "1": `${enterprise.id}`
                            }
                        },
                        "text": [
                            {
                                "value": "Enterprise",
                                "isSubject": true
                            },
                            {
                                "value": "Is",
                                "isSubject": false
                            },
                            {
                                "value": `${enterprise.name}`,
                                "isSubject": true
                            }
                        ]
                    }
                ]
            }
        })
    }

    useEffect(() => {
        getEnterprise()
    }, [])

    useEffect(() => {
        getFinanceOptions()
    }, [enterprise])

    return (
        <LoadingScreen
            hasData={enterprise}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <ConfirmModal
                        isOpen={!!actionFinanceOption}
                        buttonText={!!actionFinanceOption && !!actionFinanceOption.enabled ? "Disable" : "Enable"}
                        buttonVariant={!!actionFinanceOption && !!actionFinanceOption.enabled ? "destructive" : "primary"}
                        message={`Are you sure you would like to ${!!actionFinanceOption && !!actionFinanceOption.enabled === true ? "disable" : "enable"} ${!!actionFinanceOption && !!actionFinanceOption.name ? actionFinanceOption.name : ''} express payment options for this enterprise?`}
                        onConfirm={() => updateFinanceOptions()}
                        onClose={() => setActionFinanceOption(null)} />
                    <PageTitle title={enterprise.name} slim />
                    <PageContent slim>
                        <Stack row responsive responsiveReverse>
                            <TabPanel selectedTab={selectedTab} flex="1">
                                <Tab icon="file" component={<BuyerDetailsTab buyer={enterprise} />}>Details</Tab>
                                <Tab icon="file" onClick={suppliersTabClick}>Suppliers</Tab>
                                <Tab icon="file" onClick={workTabClick}>Work</Tab>
                            </TabPanel>
                            <Stack
                                width={["100%", "300px", "300px"]}
                                mt={[0, 0, 104]}
                                mb={[20, 0, 0]}>
                                <ButtonStack>
                                    {
                                        !!financeOptions && !!financeOptions.providers && financeOptions.providers.length > 0 &&
                                        financeOptions.providers.map(p => <Button key={p.id} variant={p.enabled ? "destructive" : "primary"} onClick={() => setActionFinanceOption(p)}>{`${!!p.enabled ? "Disable" : "Enable"} ${p.name} Express Payments`}</Button>)
                                    }
                                </ButtonStack>
                                <Box flex="0 1 auto" accent="blue">
                                    <Stack>
                                        <H2>Contact Details</H2>
                                        <Stack row responsive>
                                            <Stack flex="1">
                                                <Field label="Accounts Contact" value={`${enterprise.accountsContact.name} - ${enterprise.accountsContact.email}`} />
                                                <Field label="Business Contact" value={`${enterprise.businessContact.name} - ${enterprise.businessContact.email}`} />
                                                <Field label="Technical Contact" value={`${enterprise.technicalContact.name} - ${enterprise.technicalContact.email}`} />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ViewBuyerView