import React from 'react'
import {
    Stack,
} from 'paid-ui-lib'
import OrganisationCards from './components/OrganisationCards'
import DocumentsList from './components/DocumentsList'
import NoDataScreen from 'components/NoDataScreen'

const DocumentsTab = ({organisation, onRefresh, ...rest}) => {
    return (
        <Stack {...rest}>
            { 
                organisation.clients.length === 0 ?
                    <NoDataScreen /> :
                    organisation.clients.length === 1 ? 
                    <DocumentsList organisationId={organisation.id} enterpriseId={organisation.clients[0].enterpriseId} onRefresh={onRefresh} /> :
                    <OrganisationCards organisationId={organisation.id} />
            }
        </Stack>
    )
}

export default DocumentsTab