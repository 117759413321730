import { getRequest, postRequest } from './request-helper'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Filter,
    Collapse,
    Table,
    PaginationControl
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"
import { getQueryPageData, queryParamGenerator } from 'helpers/url-helper'

export const getFilterData = async (resourcePath, searchString, cacheValue, cacheSetter, limit = 20, queryParams) => {
    //console.log(createQueryString(queryParams))

    const url = `${resourcePath}?` + (!!searchString ? `searchString=${searchString}&limit=${limit}` : "") + (!!queryParams? queryParams.replace("?", "&") : "")
    if (url in cacheValue) {
        return cacheValue[url]
    }

    const res = await getRequest(url)
    const text = await res.text()

    let items = JSON.parse(text)

    if (!Array.isArray(items)) {
        items = items.items
    }
    const temp = { ...cacheValue }
    temp[url] = items
    cacheSetter(temp)

    return items
}

export const setFilterName = (filters, filterId, newName) => {
    const filter = filters.find(f => f.id === filterId)

    if(!!filter)
        filter.name = newName
}

export const setFilterPartName = (filters, filterId, partIndex, newName) => {
    const filter = filters.find(f => f.id === filterId)

    if(!!filter){
        const part = filter.filterParts[partIndex]
        part.name = newName
    }
}

export const removeFilter = (filters, filterId) => {
    const index = filters.findIndex(i => i.id === filterId)

    if (index > -1) {
        filters.splice(index, 1)
    }
}

export const loadFilterShowState = (location) => {
    return location.state && location.state.filters && location.state.filters.length > 0
}

export const loadActiveFilters = (location) => {
    return (location.state && location.state.filters) ? [...location.state.filters] : []
}

export const saveActiveFilters = (history, location, activeFilters) => {
    history.replace(location.pathname, { ...location.state, filters: activeFilters })
}

export const loadActiveSorting = (location) => {
    return (location.state && location.state.activeSorting) ? { ...location.state.activeSorting } : {}
}

export const saveActiveSorting = (history, location, activeSorting) => {
    history.replace(location.pathname, { ...location.state, activeSorting: activeSorting })
}

const getData = async (dataPath, filters, pageData, setDataError, showPersonalView, activeSorting, queryParams) => {
    try {
        console.log(queryParamGenerator(queryParams))
        const response = await postRequest(dataPath + (!!queryParams ? queryParamGenerator(queryParams) : ""), {
            filters,
            activeSorting,
            page: pageData.page,
            pageSize: pageData.pageSize,
            personalView: showPersonalView
        })
        const result = await response.json()
        return { items: result.items, totalResults: result.totalResults }
    } catch (err) {
        setDataError(true)
    }
}

/**
 * 
 * @param {*} filterPath 
 * @param {*} dataPath 
 * @param {*} location 
 * @param {*} setFilterData 
 * @param {*} setDataError 
 * @returns Whether the filter box is open or closed.
 */
export const initFilteredDataTable = async ({ filterPath, dataPath, location, setFilterData, setDataError, showPersonalView, queryParams, pageSize }) => {
    const pageData = !!pageSize ? { ...getQueryPageData(location.search), pageSize: pageSize} : getQueryPageData(location.search)
    let activeFilters = loadActiveFilters(location)
    let activeSorting = loadActiveSorting(location)
    
    const response = await getRequest(filterPath)
    const result = await response.json()
    const filters = result.items
    const dataResponse = await getData(dataPath, activeFilters.map(af => af.filter), pageData, setDataError, showPersonalView, queryParams)

    setFilterData({
        filters,
        quickFilters: result.quickFilters,
        data: dataResponse.items,
        filterPath,
        dataPath,
        pageData,
        activeFilters,
        activeSorting,
        totalResults: dataResponse.totalResults
    })

    return loadFilterShowState(location)
}

//TODO - This needs moving into components folder
/** DataTable with basic filtering and pagination. */
export const FilteredDataTable = ({ 
    initData, 
    showFilter, 
    transformFilters, 
    transformQuickFilters,
    setDataError, 
    setData: externalSetData, 
    transform, 
    columns, 
    onApplyFilters,
    showPersonalView, 
    sortable, 
    setParentActiveFilters, 
    setParentPageData, 
    setInitData,
    noDataScreen,
    refresh,
    setRefresh,
    queryParams,
    showResults
 }) => {
    const [activeFilters, setActiveFilters] = useState(initData.activeFilters)
    const [filterData, setFilterData] = useState({})
    const [activeSorting, setActiveSorting] = useState(sortable ? initData.activeSorting : null)
    const [data, setData] = useState(initData.data)
    const [totalResults, setTotalResults] = useState(initData.totalResults)
    const [pageData, setPageData] = useState(initData.pageData)
    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const location = useLocation()

    let availableFilters = initData.filters || []

    if (!!transformFilters && availableFilters.length > 0) {
        availableFilters = transformFilters([...availableFilters])
    }

    if (!!location.state && !!location.state.page) {
        pageData.page = location.state.page
    }

    let quickFilters = initData.quickFilters || []

    if (!!transformQuickFilters && !!quickFilters && !!quickFilters.length && quickFilters.length > 0) {
        quickFilters = transformQuickFilters([...quickFilters])
    }

    const isInitRun = useRef(true)

    useEffect(() => {
        if (setParentActiveFilters instanceof Function) {
            setParentActiveFilters(initData.activeFilters)
        }

        if (setParentPageData instanceof Function) {
            setParentPageData(initData.pageData)
        }
    }, [])

    useEffect(() => {
        resetPageData()
    }, [showPersonalView])

    useEffect(() => {
        if (isInitRun.current) {
            isInitRun.current = false
            return
        }

        const filters = activeFilters.map(af => af.filter)

        if (onApplyFilters instanceof Function) {
            onApplyFilters(filters)
        }

        history.replace(location.pathname, { ...location.state, filters: activeFilters, activeSorting: activeSorting })

        setLoading(true)
        getData(initData.dataPath, filters, pageData, setDataError, showPersonalView, activeSorting, queryParams)
            .then(response => {
                if (!!response) {
                    if (!!externalSetData) {
                        externalSetData(response.items)
                    }
                    
                    if (setInitData !== undefined) {
                        setInitData({...initData, data: response.items})
                    }
    
                    setData(response.items || [])
                    setTotalResults(response.totalResults)
                    setLoading(false)
                }                
            })

        if (setRefresh instanceof Function) {
            setRefresh(false)
        }
    }, [activeFilters, pageData, showPersonalView, activeSorting, refresh])

    const onChangeFilters = (...rest) => {
        resetPageData()

        if (setActiveFilters instanceof Function) {
            setActiveFilters.apply(null, rest)
        }

        if (setParentActiveFilters instanceof Function) {
            setParentActiveFilters.apply(null, rest)
        }
    }

    const onSort = (appliedSorting) => {
        setActiveSorting(appliedSorting)
    }

    const resetPageData = () => {
        setPageData({
            ...pageData,
            page: 1
        })

        if (setParentPageData instanceof Function) {
            setParentPageData({
                ...pageData,
                page: 1
            })
        }
    }

    const updatePageData = (data) => {
        setPageData(data)

        if (setParentPageData instanceof Function) {
            setParentPageData(data)
        }
    }

    const hasActiveFilters = () => {
        return Array.isArray(activeFilters) && activeFilters.length > 0
    }

    return (
        <Fragment>
            <Collapse isOpen={showFilter}>
                <Filter
                    flex="1"
                    mb={24}
                    value={activeFilters}
                    onChange={onChangeFilters}
                    filters={availableFilters}
                    quickFilters={quickFilters}
                    getData={(resourcePath, searchString) => getFilterData(resourcePath, searchString, filterData, setFilterData, 5, queryParams)}
                />
            </Collapse>
            <Table 
                loadingRowCount="20" 
                loading={loading} 
                columns={columns} 
                data={data} 
                transform={transform} 
                activeSorting={activeSorting} 
                onSort={sortable ? onSort : null} 
                noData={!!noDataScreen && !hasActiveFilters() ? noDataScreen : <NoDataScreen />} />
            <PaginationControl pageData={pageData} updatePageData={updatePageData} dataCount={!!data ? data.length : 0} totalDataCount={totalResults || 0} showResults={showResults} mt={20} />
        </Fragment>
    )
}